import { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react'
//import { useParams } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { useAxiosFetch } from '../components/services/useAxiosFetch';
import { QuestionProps } from '../components/Question'
import { DynamicWordInputs, ChildRef } from '../components/question_attempts/DynamicWordInputs';
import { processLiveQuestionAttempt } from '../components/services/list';
import { DropDowns } from '../components/question_attempts/DropDowns';
import { ButtonSelect } from '../components/question_attempts/ButtonSelect';
import { WordScrambler } from '../components/question_attempts/WordScrambler';
import { QuestionAttemptResults} from '../components/question_attempts/QuestionAttemptResults';
//import { FaPlayCircle } from "react-icons/fa";
import { WordsSelect } from '../components/question_attempts/WordsSelect';
import { RadioQuestion } from '../components/question_attempts/RadioQuestion';
import { ButtonSelectCloze } from '../components/question_attempts/ButtonSelecCloze';
//import { useSocket} from '../components/context/socketContext'
import { ScoreBoard } from '../components/ScoreBoard';
import SocketContext from '../components/context/Socket/Context'
import { AzureAudioPlayer } from '../components/AzureAudioPlayer';
import { SRContinuous } from '../components/question_attempts/SRContinuous';
import { useLocation } from 'react-router-dom';
//import { Counter} from '../components/Counter';
import { CounterRef } from '../components/Counter';
import { DynamicLetterInputs } from '../components/question_attempts/DynamicLetterInputs';
import {QuestionAttemptResponseProps} from '../components/services/list'

type GetQuestionProps = {
    end_of_quiz: boolean,
    question: QuestionProps,

}

/*
export type QuestionAttemptResponse =
    {
        message: string,
        question_attempt_results: {
            answer: string,
            score: number,
            error_flag: boolean,
            completed: boolean,
            correct_answer: string
        },
        question_id: number,
        question_format: number,
        words_scramble_direction: string,
        question_number: number,
        accumulated_score: number,
        question_help1: string,
        question_help2: string,
        questions_exhausted: boolean
    }
*/

export function QuizPageLive(props: any) {
    //const params = useParams<{ quizId: string, sub_category_name:string ,  startingQuestionId: string }>();
    const location = useLocation();
    const live_quiz_data = location.state;
    //console.log(" quizpagelive live quiz data", live_quiz_data)

    const user = useAppSelector(state => state.user.value)
    //const classIds = ['1', '2', '3']
    const counterRef = useRef<CounterRef>(null)

    const url = `/quizzes/${live_quiz_data.quiz_id}/get_question/${live_quiz_data.question_number}`
    

    const { data: question_response, loading, error } =
        useAxiosFetch<GetQuestionProps>({ url: url, method: 'get' })

    const [question, setQuestion] = useState<QuestionProps | undefined>()
    //const [showNextButton, setShowNextButton] = useState(false)
    const [showSubmitButton, setShowSubmitButton] = useState(true)
    const [questionAttemptResponse, setQuestionAttemptResponse] = useState<QuestionAttemptResponseProps | null>(null)
    const [endOfQuiz, setEndOfQuiz] = useState(false)
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number | undefined>()
    const [answer, setAnswer] = useState<string>()
    //const [selectedClassId, setSelectedClassId] = useState<string>("2")

    const childRef = useRef<ChildRef>(null);
    const {socket, uid, users} = useContext(SocketContext).SocketState;
   
    useEffect(() => {
        //console.log(quiz_attempt)
        if (question_response) {
            //console.log(" in quizpage live question response changed =", question_response)
            if (!question_response.end_of_quiz) {
                //console.log("nnnnnnnnn question: ", question_response.question)
                setCurrentQuestionNumber(question_response.question.question_number)
                setQuestion(question_response.question)
                setQuestionAttemptResponse(null)
                setShowSubmitButton(true)
                counterRef.current?.startCount()
            }
            else {
                //console.log("End Of Quiz")
                setEndOfQuiz(true)
            }
        }
    },[question_response])
    
    //const handleSubmit = () => {
    const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
        const button_el = event.target as HTMLButtonElement
        //console.log(button_el.disabled)
        button_el.disabled = true
        const my_answer = childRef.current?.getAnswer();
        //console.log( my_answer)
        if (my_answer) {
            setAnswer(my_answer)
            //console.log("in handleSubmit answer =", answer)
            // use the ! (exclamation mark) = non-null assertion operator to avoid warning undefined not assignable to string
            processLiveQuestionAttempt(question?.id, my_answer!)
                .then((response) => {
                    console.log("after process Live Question Attempt response = ",response)
                    setShowSubmitButton(false)
                    console.log(" nullify question")
                    setQuestion(undefined)
                    setQuestionAttemptResponse(response)

                    const live_score_params = {
                        question_number: response.question_number,
                        user_answer: response.question_attempt_results.answer,
                        score: response.question_attempt_results.score,
                        total_score: 0, user_name: user.user_name
                    }
                   
                    socket?.emit('live_score', live_score_params)
                    counterRef.current?.stopCount()

                })
                .catch(error => {
                    console.log(error)
                });
        }
        else {
            alert(" Please enter answer")
        }

    }
/*
    const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const value = event.target.value;
        setSelectedClassId(value)
        //console.log(value)
    }
*/
    //const handleNameClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
        //const el = event.target as HTMLButtonElement
         //   setTargetStudent(el.textContent!)
        //}
       
   // }

    if (endOfQuiz) {
        return (
            <div>END OF QUIZ.</div>
        )
    }


    return (
        <>
        
        
            <div className='grid grid-cols-12'>
                <div className='col-span-10 ml-10 mr-2 mt-6 flex flex-col bg-green-50 p-1 rounded-xl'>
                    <div className='bg-gray-100 p-2 rounded-xl'>
                        <div className='text-amber-800'>Question: {currentQuestionNumber}</div>
                        {question &&
                            <>
                                <div dangerouslySetInnerHTML={{ __html: question.instruction }}></div>
                                <div className='m-2'>{question.prompt}</div>
                                <div>
                                    {(question.audio_str && question.audio_str.trim().length > 0) &&
                                        <AzureAudioPlayer text={question.audio_str} />
                                    }
                                    {(question.audio_src && question.audio_src.trim().length > 0) &&
                                        <audio src={question.audio_src} controls />
                                    }
                                </div>
                                <div className='mt-3'>
                                    {question.format === 1 ? (
                                        <DynamicWordInputs content={question.content} ref={childRef} />
                                    ) : question.format === 2 ? (
                                        <ButtonSelectCloze content={question.content} ref={childRef} />
                                    ) : question.format === 3 ? (
                                        <ButtonSelect content={question.content} ref={childRef} />
                                    ) : question.format === 4 ? (
                                        <RadioQuestion question={question} ref={childRef} />
                                    ) : question.format === 6 ? (
                                        <WordScrambler content={question.content} ref={childRef} />
                                    ) : question.format === 7 ? (
                                        <SRContinuous content={question.content} ref={childRef} />
                                    ) : question.format === 8 ? (
                                        <WordsSelect content={question.content} ref={childRef} />
                                    ) : question.format === 10 ? (
                                        <DropDowns content={question.content} ref={childRef} />
                                    ) : question.format === 11 ? (
                                        <DynamicLetterInputs content={question.content} ref={childRef} />
                                    ) : (
                                        <div>UNKNOWN question format</div>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                    <div className='bg-gray-100 w-auto'>
                        {questionAttemptResponse ?
                            <>
                          
                                <QuestionAttemptResults live_flag={true} response={questionAttemptResponse} />
                            </>
                            :
                            <div></div>
                        }
                    </div>
                    <div>
                        {showSubmitButton &&
                            <button className='bg-green-300 m-1 text-lg p-1 rounded-md' onClick={handleSubmit}>Submit</button>
                        }
                    </div>
                    <div>

                    </div>

                </div>
                <div className='mt-6 bg-blue-50 rounded-md col-span-2'>
               
                <ScoreBoard classId={user.classId?.toString()} startingQuestionId={live_quiz_data.question_number}/>
             
                </div>

            </div>
            <div className='m-14'>
            </div>

        </>
    )
}

/*
 <div className='mx-20'>
            <Counter initialSeconds={0} ref={counterRef}/>
        </div>
*/