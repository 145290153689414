import { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { useAxiosFetch } from '../components/services/useAxiosFetch';
import { QuestionProps } from '../components/Question'
import { ChildRef, DynamicWordInputs,  } from '../components/question_attempts/DynamicWordInputs';
import { DynamicLetterInputs,  } from '../components/question_attempts/DynamicLetterInputs';
import { createQuestionAttempt, processQuestionAttempt } from '../components/services/list';
import { DropDowns } from '../components/question_attempts/DropDowns';
import { ButtonSelect } from '../components/question_attempts/ButtonSelect';
//import { ButtonSelectPolly } from '../components/question_attempts/ButtonSelectPolly';
import { WordScrambler } from '../components/question_attempts/WordScrambler';
import { QuestionAttemptResults} from '../components/question_attempts/QuestionAttemptResults';
//import { FaPlayCircle } from "react-icons/fa";
import { WordsSelect } from '../components/question_attempts/WordsSelect';
import { RadioQuestion } from '../components/question_attempts/RadioQuestion';
import { ButtonSelectCloze } from '../components/question_attempts/ButtonSelecCloze';
import { SRContinuous } from '../components/question_attempts/SRContinuous';
import { AzureAudioPlayer } from '../components/AzureAudioPlayer';
import ReactPlayer from 'react-player';
//import { Counter } from '../components/Counter';
//import { CounterRef } from '../components/Counter';

//import Box from 'styled-system'
//import Flex from 'styled-system'
//import Slider from 'styled-system'
import '../components/MyReactPlayer.css'
import {QuestionAttemptResponseProps} from '../components/services/list'


//interface CustomControlsProps {
   // player: ReactPlayer;
 // }

/*
  const CustomControls: React.FC<CustomControlsProps> = ({ player }) => {
    const [playing, setPlaying] = useState(false);
  
    const handlePlayPause = () => {
      setPlaying(!playing);
    };
  
    return (
      <div>
        <button onClick={handlePlayPause}>
          {playing ? 'Pause' : 'Play'}
        </button>
        <input
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={player.getCurrentTime() / player.getDuration()}
          onChange={(e) => player.seekTo(parseFloat(e.target.value))}
        />
      </div>
    );
  };
*/

type QuizAttempt = {
    question: QuestionProps,
    quiz_attempt_id: number,
    question_attempt_id: number
}

      interface PageParamsProps {
        page_num: number
        numQuestions: number;
        startTime?: number;
        endTime?: number;
      }

      interface VideoProps {
        video_url: string,
        video_pages: PageParamsProps[]
    }

export function QuizPageVideo(props: any) {
    const params = useParams<{ sub_category_name: string, quizId: string,  }>();
    const user = useAppSelector(state => state.user.value)
    const url = `/quiz_attempts/find_create_new/${params.quizId}/${user.id}`
    //const url = `/quiz_attempts/${params.quizId}/${user.id}`
    //quiz_id/:user_id",
    const { data: quiz_attempt, loading, error } =
        useAxiosFetch<QuizAttempt>({ url: url, method: 'get' })

    const videoParams:VideoProps = useLocation().state
   
    const playerRef = useRef<ReactPlayer>(null);

    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)

    //console.log("<<<<<<<<<<", location.state)
    /*
[
    {
        "index": "1"
        "numQuestions": 2,
        "startTime": 0,
        "endTime": 20
    },
    {
        "index": "2"
        "numQuestions": 1,
        "startTime": 20,
        "endTime": 30
    }
]
    */

    const [question, setQuestion] = useState<QuestionProps | undefined>()
    const [showNextButton, setShowNextButton] = useState(false)
    const [questionAttemptResponse, setQuestionAttemptResponse] = useState<QuestionAttemptResponseProps>()
    const [endOfQuiz, setEndOfQuiz] = useState(false)
    const childRef = useRef<ChildRef>(null);

    const [answer, setAnswer] = useState<string>()

    const [playing, setPlaying] = useState(false);
    
    //const location = useLocation()
   // const videoParams:VideoProps = location.state

    useEffect(() => {
        //console.log(quiz_attempt)
        setQuestion(quiz_attempt?.question)
        setShowSubmitButton(true)
    }, [quiz_attempt])
    //useEffect(() => {
        //console.log(locationState.video_pages)
      
    //}, [videoParams])

    const handlePlay = () => {
        //console.log("in handle play")
        //playerRef.current.seekTo(31);
        setPlaying(true);
    
        //if (playerRef.current && currentVideoPage?.startTime) {
          //  playerRef.current.seekTo(currentVideoPage.startTime);
       // }
    };

    const handleProgress = (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => {
        //if (currentVideoPage?.endTime && state.playedSeconds >= currentVideoPage?.endTime) {
           // console.log(" in handleProgress HERE. Stop playing because playedSeconds > video page endtime")
           //console.log(Math.floor(state.playedSeconds*1000))
           const whole_milis = Math.floor(state.playedSeconds*1000)
           if (whole_milis >= 60000)
                setPlaying(false);
          //if (playerRef.current && currentVideoPage?.startTime) {
           // playerRef.current.seekTo(currentVideoPage.startTime );
          //}
       // }
      };
   
      /*
const handleProgress = (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => {
    setPlayed(state.played);0.8068949790191651
QuizPageVideo.tsx:147 1.806081036239624
QuizPageVideo.tsx:147 2.8101359809265136
QuizPageVideo.tsx:147 3.811500141143799
QuizPageVideo.tsx:147 4.8102269809265135
QuizPageVideo.tsx:147 5.814334996185303
QuizPageVideo.tsx:147 6.815529095367432
QuizPageVideo.tsx:147 7.817319185012817
QuizPageVideo.tsx:147 8.817916982833863
QuizPageVideo.tsx:147 9.818416874114991
QuizPageVideo.tsx:147 10.82162193133545
QuizPageVideo.tsx:147 11.824173020980835
QuizPageVideo.tsx:147 12.462627
    setDuration(state.loadedSeconds); // Assuming the video is fully loaded
  };

      */

      const seekForward = () => {
        //playerRef.current?.seekTo(playerRef.current.getCurrentTime() + 10);
        playerRef.current?.seekTo(31);
    };

      const handlePlayPause = () => {
        setPlaying(!playing);
      };

      const get_next_question = () => {
        //kpham. typescript tips: non-null assertion
        //server will decide the next question to fetch. KP
        //console.log(" in Quiz Page get next question")
        createQuestionAttempt(quiz_attempt!.quiz_attempt_id)
            .then((response) => {
                if (response.end_of_quiz) {
                    setEndOfQuiz(true)
                }
                else {
                    //console.log("next question", response.question)
                    setQuestion(response.question)
                    setShowSubmitButton(true)
                    setShowNextButton(false)
                    setQuestionAttemptResponse(undefined)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const handleSubmit = () => {
        const my_answer = childRef.current?.getAnswer();
        //console.log("ZZZZZZ my_answer", my_answer)
        
        if (my_answer) {
            setAnswer(my_answer)
            //console.log("in handleSubmit answer =", answer)
            // use the ! (exclamation mark) = non-null assertion operator to avoid warning undefined not assignable to string
            processQuestionAttempt(quiz_attempt?.quiz_attempt_id, my_answer!)
                .then((response) => {
                    //setQuestion(undefined)
                    
                    setShowNextButton(true)
                    setShowSubmitButton(false)
                    setQuestion(undefined)
                    setQuestionAttemptResponse(response)
                })
                .catch(error => {
                    console.log(error)
                });
        }
        else {
            alert("Enter answer")
        }
        
    }

    if (endOfQuiz) {
        return (
            <div>END OF QUIZ.</div>
        )
    }

    return (
        <>
            { videoParams.video_url &&
            <div>
                <div className='w-3/4 h-full'>
                    <div className="player-wrapper">
                        <div className="player-overlay"></div>
                        <ReactPlayer
                            ref={playerRef}
                            playing={playing}
                            url={videoParams.video_url}
                            onPlay={handlePlay}
                            onProgress={handleProgress}
                            controls={false}
                        />
                    </div>
                </div>
                
                <div className='flex flex-row justify-start gap-2'>

                    <button onClick={handlePlayPause}>
                        {playing ? 'Pause' : 'Play'}
                    </button>
                    <button onClick={seekForward}>Forward</button>
                </div>
            </div>
            }
            <div className='flex flex-row justify-center text-lg m-1'>{params.sub_category_name}</div>
            <div className='flex flex-col mx-20 mt-4'>
            <div className='mx-10 my-6 flex flex-col'>
                <div className='bg-gray-100 p-2 rounded-xl'>
                    {question &&
                        <>
                            <div className='text-amber-800'>Question: {question.question_number}</div>
                           
                            <div dangerouslySetInnerHTML={{ __html: question.instruction }}></div>
                            <div className='m-2'>{question.prompt}</div>
                            <div>
                                {(question.audio_str && question.audio_str.trim().length > 0) &&
                                    <AzureAudioPlayer text={question.audio_str} />
                                }
                                {(question.audio_src && question.audio_src.trim().length > 0) &&
                                    <audio src={question.audio_src} controls />
                                }
                            
                            </div>
                      
                            <div className='mt-3'>
                            { question.format === 1 ? (
                                <DynamicWordInputs content={question.content} ref={childRef} />
                            ) : question.format === 2 ? (
                                <ButtonSelectCloze content={question.content} ref={childRef} />
                            ) : question.format === 3 ? (
                                <ButtonSelect content={question.content} ref={childRef} />
                            ) : question.format === 4 ? (
                                <RadioQuestion question={question} ref={childRef} />
                            ) : question.format === 6 ? (
                                <WordScrambler content={question.content} ref={childRef} />
                            ) : question.format === 7 ? (
                                <SRContinuous content={question.content} ref={childRef} />
                            ) : question.format === 8 ? (
                                <WordsSelect content={question.content} ref={childRef} />
                            ) : question.format === 10 ? (
                                <DropDowns content={question.content} ref={childRef} />
                            ) : question.format === 11 ? (
                                <DynamicLetterInputs content={question.content} ref={childRef} />
                            ) : (
                                <div>UNKNOWN question format</div>
                            )}
                            </div>
                        </>
                    }
                </div>
                <div className='bg-green-200'>
                    {questionAttemptResponse?
                        <>
                            <QuestionAttemptResults live_flag={false} response={questionAttemptResponse}  />
                        </>
                        :
                        <div></div>
                    }
                </div>
            </div>
            <div className='mx-10'>
            {showNextButton ?
                <button className='bg-amber-300 mt-0 text-lg p-1 rounded-md' onClick={get_next_question}>Next</button>
                :
                ( showSubmitButton &&
                <button className='bg-green-300 mt-0 text-lg p-1 rounded-md' onClick={handleSubmit}>Submit</button>
                )
            }
            </div>
            
            </div>
        </>
    )
}

