import React, { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react'
import SocketContext from '../components/context/Socket/Context';


  type StudentProps = 
  {
          id: number,
          user_name: string
          full_name: string
          role: string
          level: string
          classId: number
          message: string
          password: string
      
  }

  interface Props {
    items: StudentProps[] | undefined;
  }

  //export interface AudioControlRef {
   // setSrc: (source: string) => void;
    
  //}
/*
  interface MProps {
    url: string
    index: number
    user_name: string
    ref: AudioControlRef
  }
*/
interface MProps {
    url: string
    index: number
    user_name: string
}

    const AudioControl: React.FC<MProps> = ({ url, index, user_name }) => {
    //const  AudioControl = forwardRef<AudioControlRef, MProps>((props, ref) => {
  
        const [mySrc, setMySrc] = useState<string>('')
        const [userName, setUserName] = useState<string>('')
        const [newAudioReceived, setNewAudioReceived] = useState<boolean>()
        const myRef = useRef<HTMLAudioElement>(null)
  
   const {socket, uid, users} = useContext(SocketContext).SocketState;

   useEffect(() => {
        setUserName(user_name)
   },[user_name])

        useEffect(() => {
            if (socket) {
                socket.on('s3_received_recording', (arg: { username: string, path: string }) => {
                    if (arg.username.trim() === userName.trim()) {
                        //console.log("EEEEEEEEE match....")
                        setNewAudioReceived(true)
                        setMySrc(arg.path)
                    }
                })
                return () => {
                    socket?.off("s3_received_recording")
                }
            }
        }, [socket, userName])

        const loadSrc = () => {
            if (myRef.current) {
                console.log(myRef.current)
                console.log("nnn", myRef.current.src)
                //if (myRef.current.src && myRef.current.src.length > 0) {
                    myRef.current.load()
                    myRef.current.play()
                    .then (() => setNewAudioReceived(false)

                    )
                    .catch(error => {
                        console.error('Error playing audio:', error);
                        alert(" no audio src available!")
                        // Handle the error, such as displaying a message to the user
                      });
                //}
            }
        }

    return (
        <div className='flex flex-row gap-2'>
            <div>{userName}</div>
              
                { newAudioReceived && 
                  <div>
                    <div><button className='bg-amber-400 rounded-md p-1' onClick={loadSrc}>Play</button></div>
                    </div>
                }
              
                   
            
            <audio id={userName} controls
                src={mySrc} ref={myRef}
            />
        </div>
    )
}

export const RecordViewTeacher: React.FC<Props> = ({ items }) => {
    const liveScoreRefs = useRef<HTMLInputElement[]>([]);
    //const childRefs = useRef<AudioControlRef[]>([]);
    const {socket, uid, users} = useContext(SocketContext).SocketState;
    const send_live_score: MouseEventHandler<HTMLButtonElement> = (event) => {
        const el = event.target as HTMLButtonElement
       
        const name = el.id.split('_')[0]
        const index = el.id.split('_')[1]
        console.log("UUUUU", index)
        console.log(liveScoreRefs.current[parseInt(index)].value)
        const arg = { target_student: name, score: liveScoreRefs.current[parseInt(index)].value}
           //alert("emiet emable live picture")
       socket?.emit('live_score_new', arg)
      }

    return (
        <>
            {items && items.map((item, index) => (
                <div className='flex flex-row gap-2'>
                    <div>
                    <AudioControl url='' index={index} user_name={item.user_name}
                    />
                    </div>
                    <div className='p-2'>
                        <input className='bg-blue-200' type="text" size={10}
                            ref={(element) => {
                                if (element) {
                                    liveScoreRefs.current[index] = element;
                                }
                            }}
                        />
                        <button className='bg-amber-500 rounded-md p-2 mx-2' id={`${item.user_name}_${index}`} onClick={send_live_score}>Send Live Score</button>
                    </div>
                </div>
            ))}
        </>
    );
}

/*
<audio id={controlId} controls preload="auto">
        <source src={url} type="audio/mpeg"/>
    </audio>
*/

// <audio src="" controls />
/*
 return (
    <div>
      {items && items.map((item, index) => (
        <>
        <AudioControl url='' index={index} user_name={item.user_name}
        ref={(ref) => {
            if (ref)
                return childRefs.current[index] = ref
        }
        }
        />
        </>
      ))}
    </div>
  );
*/