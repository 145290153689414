import React from 'react'
import { useAppSelector } from '../redux/store';
import { useAxiosFetch } from './services/useAxiosFetch';
import { Link, NavLink } from 'react-router-dom';

type Category = {
    id: number;
    name: string;
    sub_categories: SubCategory[]
}

interface SubCategory {
  id: number,
  name: string
  sub_category_number: number
  level: string
}

export function NavigationBar(props: any) {
    
    const user = useAppSelector(state => state.user.value)
    const { data: categories, loading, error } = useAxiosFetch<Category[]>({ url: '/categories', method: 'get' });

    return (
        <>
         <div className='flex flex-row justify-left gap-2'>
          <div>Welcome: {user.user_name}</div>
          <div className='bg-amber-600 p-1 rounded-md text-white mr-1'><Link to="/">Home</Link></div>
            <div className='text-red-600 text-md'>
              <Link to="/logout">Log out</Link>
            </div>
        
          </div>
             <div className="flex flex-row p-0 gap-1 justify-center">
              {categories?.map((category) => (
                <div key={category.id} className='flex flex-row'>
                  <NavLink
                    to={`/categories/${category.id}`}
                    className={({ isActive }) => {
                      return isActive ? 'text-lg bg-cyan-300 p-2 rounded-t-md' : 'rounded-md text-lg bg-cyan-100 p-2 hover:bg-cyan-200';
                    }}
                  >
                    {category.name}
                  </NavLink>
                </div>
              ))}
            </div>
        </>
    )
}
