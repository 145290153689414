import React, { useEffect, useRef, useState } from 'react';
import Peer, { DataConnection, MediaConnection } from 'peerjs';

interface Props { }

const PeerComponentStudent: React.FC<Props> = () => {
  const [peerId, setPeerId] = useState<string>('');
  const [remotePeerId, setRemotePeerId] = useState<string>('');
  const [conn, setConn] = useState<DataConnection | null>(null);
  const [message, setMessage] = useState<string>('');
  const peerRef = useRef<Peer | null>(null);

  const [call, setCall] = useState<MediaConnection | null>(null);

  const localStreamRef = useRef<MediaStream | null>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const peerInstance = useRef<Peer | null>(null);


  let renderVideo = (stream:any) => {
    const videoEl: HTMLVideoElement | null = document.querySelector('.remote-video');
    if (videoEl) {
      videoEl.srcObject = stream
    }
  };

  useEffect(() => {
    const initializePeer = async () => {
      //const peer = new Peer();
      const peer = new Peer({
        config: {
          iceServers: [
            {
              urls: "stun:stun.relay.metered.ca:80",
            },
            {
              urls: "turn:global.relay.metered.ca:80",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turn:global.relay.metered.ca:80?transport=tcp",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turn:global.relay.metered.ca:443",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turns:global.relay.metered.ca:443?transport=tcp",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
        ],
      
        }
      })

      peer.on('open', (id) => {
        setPeerId(id);
      });

      peer.on('call', (incomingCall) => {

        incomingCall.answer(localStreamRef.current!);
        setCall(incomingCall);
        console.log(incomingCall.connectionId)

        incomingCall.on('stream', (remoteStream) => {
          console.log("HEREEEEE")
          if (remoteVideoRef.current) {
            console.log("HEREEEEEEEEEOOOOOO")
            remoteVideoRef.current.srcObject = remoteStream;
          }
        });
      });

      peerInstance.current = peer;
    };

    initializePeer();

    return () => {
      if (peerInstance.current) {
        peerInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const getLocalStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current = stream;
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    getLocalStream();
  }, []);

  const handleInitiateCall = () => {
    console.log("initiate call....")
    if (peerInstance.current && localStreamRef.current && remotePeerId) {
      const newCall = peerInstance.current.call(remotePeerId, localStreamRef.current);
      console.log('calling setCall')
      setCall(newCall);
      console.log('after calling setCall')
      newCall.on('stream', (remoteStream) => {
        console.log("student reeviec remote stream")
        if (remoteVideoRef.current) {
          console.log("student reeviec remote stream YES")
          remoteVideoRef.current.srcObject = remoteStream;
        }
      });
    }
  };

  return (
    <>
    <div>
      <div>IN PEER FOR STUDENT</div>
      <h1>Your Peer ID: {peerId}</h1>
      <input 
        type="text" 
        placeholder="Enter remote Peer ID" 
        value={remotePeerId} 
        onChange={(e) => setRemotePeerId(e.target.value)} 
      />

    </div>
    <button onClick={handleInitiateCall}>Initiate Call</button>
      <video ref={remoteVideoRef} autoPlay playsInline />
  
    </>
  );
};

export default PeerComponentStudent;