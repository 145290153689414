import React, { useState } from 'react';
//import PropTypes from 'prop-types';
//import Context from '../App/App.js'
import { useAppDispatch } from '../../redux/store';
import { setCurrentUser } from '../../redux/current_user'

import {login} from '../services/list'
import { Button } from "flowbite-react";
//import {UserContext} from '../context/UserContext'
  
/*
type ChildProps = {
  onLoginSuccess: Function
}
*/

export default function Login(props: {onLoginSuccess: Function }) {
 
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const dispatch = useAppDispatch()
  
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    login({username: userName, password: password} )
    .then (response => {
      props.onLoginSuccess(response.token)
      //setUser({user_name: 'test', level: 'basic', role: 'student'})
      
      dispatch(setCurrentUser({value: response.user}))
    })
    .catch(error => {
      console.log("Login error",error)
      //alert(error.response.data.error)
    })
  }

  return (
    <>
    <div className='flex flex-row justify-center'>
    <div className="flex flex-col m-10">
      <div className='text-lg mb-2'> Log in</div>
      <form>
        <label>
          <p>Username</p>
          
          <input className="bg-amber-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto p-2.5"
          type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input className="bg-amber-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto p-2.5"
          type="text" onChange={e => setPassword(e.target.value)} />
        </label>
        <button className='bg-amber-500 m-2 p-2 hover:bg-amber-600 rounded-md' onClick={handleSubmit}>Submit</button>
      </form>
    </div>
    </div>
    </>
  )
}

/*

  <div>
          <br />
          <Button onClick={handleSubmit}>Submit</Button>
        </div>

return(
    <div className="login-wrapper">
      <h2>Log In</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          type={
            showPassword ? "text" : "password"
          }
          onChange={e => setPassword(e.target.value)} />
        </label>
        <label htmlFor="check">&nbsp;Show Password&nbsp;</label>
                <input
                    id="check"
                    type="checkbox"
                    value={showPassword}
                    onChange={() =>
                        setShowPassword((prev) => !prev)
                    }
                />
        <div>
          <br />
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </form>
    </div>
  )
*/