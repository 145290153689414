import axios from "axios";
import { QuestionProps } from "../Question";

let rootpath = ''
if (process.env.NODE_ENV === "production") {
    rootpath = 'https://fullstack-kp-f6a689f4a15c.herokuapp.com'
    //rootpath = 'https://www.tienganhtuyhoa.com'
}
else if (process.env.NODE_ENV === "development"){
    rootpath = 'http://localhost:5001'
    
}
else {
    console.log("invalid NODE_ENV ")
}


type Credentials = {
    username: string
    password: string
}

type QuestionAttempt = {
        end_of_quiz: boolean,
        question : QuestionProps,
        isErrorneous : false,
        question_attempt_id: number
}

type GetQuestionProps = {
    end_of_quiz: boolean,
    question: QuestionProps,

}

type GetQuizQuestionProps = {
    questions: QuestionProps[]
}

export type QuestionAttemptResponseProps =
{
    message :  string ,
    question_attempt_results : {
        answer :  string ,
        score : number,
        error_flag : boolean,
        completed : boolean,
        correct_answer : string
     },
    question_id : number,
    question_format : number,
    words_scramble_direction : string ,
    question_number : number,
    question_help1: string,
    question_help2: string, 
    accumulated_score : number,
    questions_exhausted : boolean
}

type StudentsProps = 
    {
        users: {
          id: number,
            user_name: string
            full_name: string
            role: string
            level: string
            classId: number
            message: string
            password: string
        }[]
    }

type ClassProps = 
    {
        class_id: number,
        class_name: string,
        users: StudentProps[] | undefined
    }

    type StudentProps = 
    {
       
            id: number,
            user_name: string
            full_name: string
            role: string
            level: string
            classId: number
            message: string
            password: string
        
    }

//({ url: `/classes/${props.classId}`, method: 'get' });
export async function getAClass(id: string): Promise<ClassProps> {
  //console.log("in getAClass")

  const url = `${rootpath}/api/classes/${id}`
  const response = await axios.get(url)
 // console.log("UUUU response.data", response.data)
  return response.data

}

export async function upload_form_data_to_s3(formData: any, config: any) {
  const url = `${rootpath}/api/uploads/do_upload_single` 
  //console.log(" in list upload..url =", url)
  //console.log("form data: ", formData)
   axios.post(url, formData, config).then((response) => {
     return response;
   });
}


export async function getCategories() {
    //console.log("in getCategories")

    const url = `${rootpath}/api/categories`
    const response = await axios.get(url)
    return response.data

}

export async function createQuestionAttempt(quiz_attempt_id: number): Promise<QuestionAttempt> {
    // server will decide the next question to fetch
    const url = `${rootpath}/api/quiz_attempts/${quiz_attempt_id}/creat_next_question_attempt`
    const response = await axios.post(url)
    return response.data
  }

//function add(x: number, y: number): number {
export async function processQuestionAttempt(quiz_attempt_id: number | undefined, user_answer: string):  Promise<QuestionAttemptResponseProps> {
    const url = `${rootpath}/api/question_attempts/${quiz_attempt_id}/process_attempt`
    const response = await axios.post(url,{user_answer: user_answer})
    return response.data
  }

  export async function processLiveQuestionAttempt(question_id: number | undefined, user_answer:string): Promise<QuestionAttemptResponseProps> {
    const url = `${rootpath}/api/question_attempts/process_live_attempt/${question_id}/`
    const response = await axios.post(url,{user_answer: user_answer})
    return response.data
  }
  export async function getNextQuestion(quiz_id: string | undefined, question_number: number | undefined): Promise<GetQuestionProps> {
   
    const url = `${rootpath}/api/quizzes/${quiz_id}/get_question/${question_number}`
    console.log("HHHHH url", url)
    const response = await axios.get(url)
    return response.data
  }

  export async function updateQuestion(id: string | undefined, body: {instruction: string}) {
    //console.log(" in updateQuestion id ",id )
    //console.log(" in updateQuestion id ",body )
    const url = `${rootpath}/api/questions/${id}`
    console.log("HEE url", url)
    const response = await axios.put(url, body)
    return response
    //return "test"
  }
  

  export async function getQuizWithQuestions(quiz_id: number): Promise<GetQuizQuestionProps> {
    const url = `${rootpath}/api/quizzes/${quiz_id}/get_questions` 
    const response = await axios.get(url)
    return response.data
  
  }
export async function login(credentials: Credentials) {
    if (credentials.username.length === 0) {
        alert("Please enter username")
        return false
    }
    else if (credentials.password.length === 0) {
        alert("please enter password")
        return false
    }
    let url = `${rootpath}/sessions`
    const response = await axios.post(url, credentials)
    //response is a promise
    return response.data
}

export async function getIds() {
    //get ALL game ids, sub_category ids, unit ids, quizzes id
    const url = `${rootpath}/api/utils/get_ids` 
    const response = await axios.get(url)
    //console.log(response.data)
    return response.data
  }

  export async function getAGame(id: string | undefined) {
    const url = `${rootpath}/api/matching_games/${id}` 
    const response = await axios.get(url)
    return response.data
  
  }
