import React, { useEffect, useRef, useState } from 'react';
import Peer, { DataConnection, MediaConnection } from 'peerjs';

interface Props {}

const PeerComponentTeacher:  React.FC<Props> = () => {
  const [peerId, setPeerId] = useState<string>('');
  const [remotePeerId, setRemotePeerId] = useState<string>('');
  const [conn, setConn] = useState<DataConnection | null>(null);
  const [message, setMessage] = useState<string>('');
  const peerRef = useRef<Peer | null>(null);
  const items= ['aaaaa', 'bbbbb']
  
  const [call, setCall] = useState<MediaConnection | null>(null);

  const localStreamRef = useRef<MediaStream | null>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const peerInstance = useRef<Peer | null>(null);

  const videoRefIndex = useRef<number>(0)

  const itemRefs = useRef<HTMLVideoElement[]>([]);

  const video_div_ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const initializePeer = async () => {
      //const peer = new Peer();
      const peer = new Peer({
        config: {
          iceServers: [
            {
              urls: "stun:stun.relay.metered.ca:80",
            },
            {
              urls: "turn:global.relay.metered.ca:80",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turn:global.relay.metered.ca:80?transport=tcp",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turn:global.relay.metered.ca:443",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
            {
              urls: "turns:global.relay.metered.ca:443?transport=tcp",
              username: "c272ce74be69d6f69c0f13ab",
              credential: "MvofS5mzj2TCXVDZ",
            },
        ],
      
        }
      })
      peer.on('open', (id) => {
        setPeerId(id);
      });
      peer.on('call', (incomingCall) => {
        incomingCall.answer(localStreamRef.current!);
        setCall(incomingCall);
        console.log(incomingCall.connectionId)
       
        incomingCall.on('stream', (remoteStream) => {
          console.log("HEREEEEE on STREAM wwwwww", videoRefIndex)
          remoteStream.addEventListener('inactive', () => {
            console.log('****** Remote stream is inactive');
            // Perform actions when the remote stream becomes inactive
            //console.log(video_div_ref.current?.childNodes[0])
            console.log(video_div_ref.current?.childNodes.length)
            video_div_ref.current?.removeChild(video_div_ref.current.childNodes[0])
          });
          
          if (itemRefs.current[videoRefIndex.current]) {
            console.log("EEEEE")
            //console.log(remoteStream)
            itemRefs.current[0].srcObject = remoteStream;
            videoRefIndex.current = videoRefIndex.current + 1
          }
        });
      });

      peerInstance.current = peer;
    };
    initializePeer();

    return () => {
      if (peerInstance.current) {
        peerInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const getLocalStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current = stream;
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    getLocalStream();
  }, []);

  
  return (
    <>
    <div>
      <div>IN PEER FOR TEACHER</div>
      <h1>Your Peer ID: {peerId}</h1>
      <input 
        type="text" 
        placeholder="Enter remote Peer ID" 
        value={remotePeerId} 
        onChange={(e) => setRemotePeerId(e.target.value)} 
      />

    </div>
    
     
      <div ref={video_div_ref}>
            {items.map((item, index) => (
              <video 
                key={index} 
                id={`video_${index.toString()}`}
                ref={(element) => {
                  if (element) {
                    itemRefs.current[index] = element;
                  }
                }}
                autoPlay playsInline />
              
            ))}
          </div>
    </>
  );
};

export default PeerComponentTeacher;