import {MouseEventHandler, useContext, useEffect, useRef, useState} from 'react'

import { useAppSelector , useAppDispatch} from '../redux/store'
import {  Outlet, useNavigate } from 'react-router-dom'
import { useAxiosFetch } from './services/useAxiosFetch'
import SocketContext from './context/Socket/Context'
import ReactTextareaAutosize from 'react-textarea-autosize'
import PeerComponentTeacher from './PeerComponentTeacher'
import { NavigationBar } from './NavigationBar'
import { SendLiveQuestion } from './SendLiveQuestion'
import { SendLiveText } from './SendLiveText'
import { RecordViewTeacher } from '../pages/RecordViewTeacher'
import SimplePeer from './SimplePeer'
import { SendLivePicture } from './SendLivePicture'
import { getAClass } from './services/list'

  type Category = {
    id: number;
    name: string;
    sub_categories: SubCategory[]
}

interface SubCategory {
  id: number,
  name: string
  sub_category_number: number
  level: string
}

type StudentProps = 
{
        id: number,
        user_name: string
        full_name: string
        role: string
        level: string
        classId: number
        message: string
        password: string
    
}

export function HomeTeacher() {
    const user = useAppSelector(state => state.user.value)
    const { data: categories, loading, error } = useAxiosFetch<Category[]>({ url: '/categories', method: 'get' });
    const [gameId, setGameId] = useState<string>()
    const strings: string[] = ["bg-red-200", "bg-blue-200", "bg-purple-200", "bg-cyan-200", "bg-slate-200",
  "bg-lime-200", "bg-green-200", "bg-amber-100", "bg-orange-100",  "bg-emerald-200", "bg-sky-200", "bg-indigo-100", "bg-rose-100",
  "bg-yellow-200", "bg-fuchsia-200", "bg-teal-100",
  ];

    const {socket, uid, users} = useContext(SocketContext).SocketState;
    const [targetStudent , setTargetStudent ] = useState<string>('everybody')
    const [targetClass , setTargetClass ] = useState<string>('')
    const [classstudents, setClassstudents] = useState<StudentProps[] | undefined>()

    const liveScoreRefs = useRef<HTMLInputElement[]>([]);

    const navigate = useNavigate();

/*
        {user.role.include('teacher') ?
        <PeerComponentTeacher items={['aaaaa', 'bbbbb']} />
        :
        <PeerComponentStudent />
        }
//
*/

useEffect(() => {
  if (socket) {
    console.log("MMMMMXXXXXXXXX HOME TEACHER socket id", socket.id)
     if (socket.id)
        socket.emit('join', {user_name: user.user_name, socket_id: socket.id})
     else
        console.log("HomeTeacher: UNDEFINED SOCKET ID")
  }
},[socket])

const enableGame = () => {
   
  function getRandomString() {
    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
  }
  const randombackground = getRandomString()
  socket?.emit("enable_game", {game_id: gameId, backcolor: randombackground})
}

/*
enable_simple_peer
*/

const send_enable_audio_recorder = () => {
  if (targetClass.length === 0) {
     alert("Enter target class")
     return 
 }
 else if (targetStudent.length === 0) {
     alert("Enter target student")
     return 
 }
 else {
 const arg = { target_student: targetStudent, target_class: targetClass}
     //alert("emiet emable live picture")
 socket?.emit('enable_audio_recorder', arg)
 }
}


const send_enable_simple_peer = () => {
  
 const arg = {}
     //alert("emiet emable live picture")
    navigate('/simple_peer')
 socket?.emit('enable_simple_peer', arg)
 
}


useEffect(() => {
  if (targetClass.length > 0 ) {
      getAClass(targetClass)
      .then((response) => {
              setClassstudents(response.users)
      })
      .catch(error => {
          console.log(error)
      });
  }
},[targetClass])

const handleNameClick: MouseEventHandler<HTMLButtonElement> = (event) => {
  const el = event.target as HTMLButtonElement
  setTargetStudent(el.textContent as string)
}

//const send_live_score = () => {

//}

    return (
        <>
        
        <div className='m-14'>
        
          <div className="grid grid-cols-2-2 mt-0 justify-auto">
           <NavigationBar />
          </div>
          <Outlet />
        </div>
        <span><button className='bg-blue-600 text-white rounded-md hover:bg-blue-700 p-1 px-2' onClick={send_enable_audio_recorder}>Enable Audio Recorder</button></span>
        <div className='flex flex-row justify-start gap-2 mt-2'>
                            <span className='mx-1 '>Class Id:<input className='bg-amber-300 px-2 text-sm rounded-md w-4/12' type="text" value={targetClass}
                                onChange={e => setTargetClass(e.target.value)}

                            /></span>
                            <span className='mx-1 '>Target student:<input className='bg-amber-300 px-2 text-sm rounded-md w-4/12' type="text" value={targetStudent}
                                onChange={e => setTargetStudent(e.target.value)}
                            /></span>

                        </div>
                        <div className='flex flex-row justify-end gap-2 mt-2'>
                <button className='bg-blue-600 text-white p-1 rounded-md' onClick={handleNameClick}>everybody</button>         
                </div>
                <RecordViewTeacher items={classstudents} />
        <div className='mx-14'>
          <SendLiveText students={classstudents}/>
        </div>
        <div className='mx-14'>
          <SendLiveQuestion />
        </div>
        <div className='mx-14'>
          <SendLiveQuestion />
        </div>
        <div>
          <SendLivePicture user_name = {user.user_name} />
        </div>
        <div><button className='bg-amber-500 p-1' onClick={send_enable_simple_peer}>Enable Simple Peer</button></div>
        <div className='mx-14'>
                <button className='bg-yellow-400 p-1 rounded-md m-4' onClick={enableGame}>Enable Game</button>
                <span className='mx-1 '><input className='bg-green-200 px-2 text-sm rounded-md w-4/12' type="text" value={gameId}
                    onChange={e => setGameId(e.target.value)}
                /></span>
            </div>
        <ReactTextareaAutosize className='bg-cyan-100 w-auto m-14 px-3' id="prompt"  value={user.message} />
        <p className='mx-14 text-gray-400'>
          {socket?.id !== undefined ?  
              <div>Socket Id: {socket.id}</div>
              :
              <div className='text-lg text-red-600'>EMPTY socket id.</div>
          }
       </p>
     
        </>
    )
}

/*
    <div>
            {items.map((item, index) => (
              <video 
                key={index} 
                id={index.toString()}
                ref={(element) => {
                  if (element) {
                    itemRefs.current[index] = element;
                  }
                }}
                autoPlay playsInline />
              
            ))}
          </div>
           <h1>Your Peer ID: {peerId}</h1>
*/