import { useAxiosFetch } from './services/useAxiosFetch'
import { useAppSelector} from '../redux/store'
import { MouseEventHandler, useContext, useEffect, useRef, useState } from 'react'
import SocketContext from './context/Socket/Context'

 type ClassProps = 
    {
        class_id: number,
        class_name: string,
        users: {
            user_name: string
            full_name: string
            role: string
            level: string
            classId: number
            message: string
            password: string
        }[]
    }

export function ScoreBoard1(props:{classId: string | undefined }) {
    const { data: my_class, loading, error } = useAxiosFetch<ClassProps>({ url: `/classes/${props.classId}`, method: 'get' });
    const {socket, uid, users} = useContext(SocketContext).SocketState;
    
    const user = useAppSelector(state => state.user.value)
    const [targetStudent, setTargetStudent] = useState<string>()
    const studenDivRefs = useRef<HTMLSpanElement[]>([]);
   

    useEffect(() => {

        if (socket) {
            socket?.on('live_score_new', (arg : { target_student: string, score: string}) => {
                //console.log(">>>>>>>>>> arg====", arg)
                let for_student_index = 0
                studenDivRefs.current.forEach((my_div, index) => {
                    //console.log("MMMMMXXXX",my_div.childNodes[0].textContent?.trim())  //student name span
                    //console.log("<<<<<< user name", arg.target_student)
                    if (arg.target_student.trim() === my_div.childNodes[0].textContent?.trim()) { //student name span
                        for_student_index = index
                    }
                });
                //console.log(" for student index:", for_student_index)
                //console.log("MM", studenDivRefs.current[for_student_index])
                let target_student_div = studenDivRefs.current[for_student_index]
                target_student_div.childNodes[2].textContent = arg.score   //score span ref
                let current_total = target_student_div.childNodes[3].textContent  //total score span ref
                if (current_total) {
                    //console.log(".... total present")
                    let total_score_int = parseInt(current_total)
                    total_score_int = total_score_int + parseInt(arg.score)
                    target_student_div.childNodes[3].textContent = total_score_int.toString()
                }
                else {
                    //console.log(".... total empty")
                    target_student_div.childNodes[3].textContent = arg.score
                }
                
            })
            return () => {
                socket?.off("live_score_new")
            }
        }
    }, [socket, user.user_name, user.role])

    
    const handleNameClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
        const el = event.target as HTMLButtonElement
            setTargetStudent(el.textContent!)
        //}
       
    }

  

    return (
        <>
          
            {my_class &&
                <>
                   <div>
                    <div className='text-amber-800 mb-2'>My class:</div>
                    {my_class.users.map((student, index) => (
                        <div key={index} ref={(el) => {
                            if (el) {
                                studenDivRefs.current[index] = el;
                            }
                        }}>

                            <span className='mx-1 name' onClick={handleNameClicked}>{student.user_name}</span>
                                <span className='mx-2 text-orange-600'></span>
                                <span className='mx-2 text-orange-700 font-bold' ></span>
                                <span className='mx-2 text-blue-700' ></span>

                        </div>
                    ))
                    }
                </div>
                  
                </>
            }
        </>
    )
}

/*
<input className='bg-blue-200' type= "text" size={10}  
                                ref={(element) => {
                                  if (element) {
                                    liveScoreRefs.current[index] = element;
                                  }
                                }}
                            />
                            <button className='bg-amber-500 rounded-md p-2' id={`${student.user_name}_${index}`} onClick={send_live_score}>Send Live Score</button>
*/