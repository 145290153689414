import { useAppSelector , useAppDispatch} from '../redux/store'
//import { useSocket} from '../components/context/socketContext'
import { HomeTeacher } from './HomeTeacher'
import { HomeStudent } from './HomeStudent'
import SocketContext from './context/Socket/Context'
import { useContext, useEffect } from 'react'


export function Home() {
    const user = useAppSelector(state => state.user.value)
    //const {socket, uid, users} = useContext(SocketContext).SocketState;
/*
        {user.role.include('teacher') ?
        <PeerComponentTeacher items={['aaaaa', 'bbbbb']} />
        :
        <PeerComponentStudent />
        }
//
*/
    

    return (
        <>
        {user.role === "teacher" ?
          <HomeTeacher />
          :
          <HomeStudent />
        }
    
        </>
    )
}
