import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import AWS from 'aws-sdk';

// Configure AWS SDK
/*
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  },
});
*/

AWS.config.update ({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
  })
  //REACT_APP_AWS_REGION
  

// Create a Polly client
const polly = new AWS.Polly();

// Define the context type
interface PollyContextType {
    synthesizeSpeech: (text: string[], voiceId?: string) => Promise<void>;
    audioUrls: string[] | null;
  }
// Create the context
const PollyContext = createContext<PollyContextType | null>(null);

// Create the provider component
export const PollyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    //kpham: use generic type argument <{ children: ReactNode }> for React.FC to 
    //avoid error
  //const [audioUrls, setaudioUrls] = useState<string | null>(null);
  const [audioUrls, setaudioUrls] = useState<string[] | null>(null);

  const synthesizeSpeech = async (text: string[], voiceId: string = 'Joanna') => {
    const my_audio_urls = []
    if (text !== undefined) {
      for (let i = 0; i < text.length; i++) {
        try {
          const params = {
            Text: text[i],
            OutputFormat: 'mp3',
            VoiceId: voiceId,
          };

          const data = await polly.synthesizeSpeech(params).promise();

          const blob = new Blob([data.AudioStream as ArrayBuffer], { type: 'audio/mpeg' });
          const url = URL.createObjectURL(blob);
          my_audio_urls.push(url)

          //setaudioUrls(my_audio_urls);
        } catch (error) {
          console.error('Error synthesizing speech:', error);
        }
      }
      setaudioUrls(my_audio_urls);
    }
  };

  return (
    <PollyContext.Provider value={{ synthesizeSpeech, audioUrls }}>
      {children}
    </PollyContext.Provider>
  );
};

// Create a hook to access the context
export const usePollyMultiple = () => {
  const context = useContext(PollyContext);
  if (!context) {
    throw new Error('usePolly must be used within a PollyProvider');
  }
  return context;
};