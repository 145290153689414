import { useLocation } from "react-router-dom";
//import VoiceRecorder from "../components/VoiceRecorder";
import { useEffect, useState } from "react";
//import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
//import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
//import { upload_form_data_to_s3 } from "../components/services/list";
//import SocketContext from "../components/context/Socket/Context";
import { useAppSelector } from "../redux/store";
//import { AzureButton } from "../components/AzureButton";
import { AzureAudioPlayer } from "../components/AzureAudioPlayer";

export function LivePicture(props: any) {
    const user = useAppSelector(state => state.user.value)
    const [audioDescription, setAudioDescription] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [imgSrc, setImgSrc] = useState('')
    const [width, setWidth] = useState<number>(200)
    const [height, setHeight] = useState<number>(200)
    const [showAudio, setShowAudio] = useState<boolean>(true)
    const location = useLocation();
    const live_picture_data = location.state;
   
    useEffect(() => {
        console.log("iiiiixxxxxx", live_picture_data)
        setAudioDescription(live_picture_data.audio_description)
        setDescription(live_picture_data.description)
        setWidth(live_picture_data.width)
        setHeight(live_picture_data.height)
        if (live_picture_data.type === 'general') {  // general pictures
            setImgSrc(live_picture_data.picture_url)
        }
        else { // personal pictures
          /*
{
    "picture_url": "https://kevinphambucket.s3.us-east-1.amazonaws.com/images/students",
    "type": "personal",
    "audio_description": "me",
    "width": 100,
    "height": 100,
    "target_student": "everybody",
    "target_class": "3"
}
          */
          //live_picture_data.picture_url = `https://kevinphambucket.s3.us-east-1.amazonaws.com/images/students`
           const url = `${live_picture_data.picture_url}/${user.user_name}/${live_picture_data.description}.jpeg`
           console.log("MMMEEEEEE url", url)
           //https://kevinphambucket.s3.us-east-1.amazonaws.com/images/students/basic3/test.jpeg
           setImgSrc(url)
        }

        setShowAudio(live_picture_data.show_audio)
        //if (live_picture_data.target_student.trim() === 'everybody' && live_picture_data.target_class.trim() === user.classId?.toString()) {
          //console.log("live text for everybody in my class", user.classId)
         // if (live_picture_data.type === 'me') {
         //   const src = `https://kevinphambucket.s3.us-east-1.amazonaws.com/images/students/${user.user_name}/me.jpeg`
          //  setImgSrc(src)
           
         // }
        //  else {

        //  }
          //setSendToS3(false)
        //}

    },[live_picture_data, user.user_name])
//parentFunc: (selected_text: string) => void
    return (
        <>
        
            <div className="mt-5">
            <div><img style={{width: width, height:height}}  src = {imgSrc} alt="card" />
            </div>
            { audioDescription.length > 0 && 
            <AzureAudioPlayer text={audioDescription} />
            }
            </div>
        </>
    )
}

/*
 {blob && (
        <AudioVisualizer
          blob={blob}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={'#f76565'}
        />
      )}

      {blob && (
        <AudioVisualizer
          blob={blob}
          width={500}
          height={75}
          barWidth={3}
          gap={2}
          barColor={'lightblue'}
        />
      )}
*/